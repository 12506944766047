import ReactGA from 'react-ga';

export default class AnalyticsService {
    constructor() {
        ReactGA.initialize('UA-133428932-1');

        // initialize functions
        this.pageView = this.pageView.bind(this);
        this.event = this.event.bind(this);
    }

    pageView(page) {
        ReactGA.pageview(page);
    }

    event(category, action) {
        ReactGA.event({
            category: category,
            action: action
        });
    }
}