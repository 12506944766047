import React, { Component } from "react";
import {
  HashRouter,
  Redirect
} from "react-router-dom";
import AuthService from './components/AuthService';
import ApiService from './components/ApiService';
import AnalyticsService from './components/AnalyticsService';
import './Register.css';
 
class Register extends Component {
	constructor() {
    super();
    this.state = {
    	username: '',
      password: '',
      passwordConfirm: '',
      passwordsMatch: true,
      redirectHome: false
    };

    this.Auth = new AuthService('localhost');
    this.Api = new ApiService();
    this.AnalyticsService = new AnalyticsService();
  }

  onChange = (e) => {
    /*
      Because we named the inputs to match their
      corresponding values in state, it's
      super easy to update the state
    */
    this.setState({ [e.target.name]: e.target.value });
  }

  onPWChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, function () {
      if(this.state.password !== this.state.passwordConfirm) {
        this.setState({ passwordsMatch: false });
      } else {
        this.setState({ passwordsMatch: true });
      }
    });
  }

  onSubmit = (e) => {
    e.preventDefault();

    var payload = {
    	username: this.state.username,
    	password: this.state.password
    };

    this.Api.createUser(payload)
    .then((response)=> {
      this.Auth.login(this.state.username,this.state.password).then(res => {
        this.setState({
          username: this.state.username,
          password: '',
          redirectHome: true
        });
      });
    });
  }

  render() {
    this.AnalyticsService.pageView('/register');
		const redirectHome = this.state.redirectHome;
    if (redirectHome === true) {
      window.confirm(`Registered successfully as ${this.state.username}!`);
      return <Redirect to="/" />;
    }

    if(this.Auth.loggedIn()) {
      return <Redirect to="/" />;
    }

    const { username, password, passwordConfirm, passwordsMatch } = this.state;

    let passwordHelp;
    if (!passwordsMatch) {
      passwordHelp = <small id="passwordHelp" class="form-text text-muted">Passwords do not match</small>
    }

    return (
			<HashRouter>
        <div class="register-wrapper">
  				<div class="card">
  				  <div class="card-body">
  				    <h5 class="card-title">Register</h5>
  				    <form onSubmit={this.onSubmit}>
  						  <div class="form-group">
  						    <input class="form-control" id="exampleInputEmail1" placeholder="Username" name="username" value={username} onChange={this.onChange} />
  						  </div>
  						  <div class="form-group">
  						    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" name="password" value={password} onChange={this.onPWChange}/>
  						  </div>
  						  <div class="form-group">
  						    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password confirm" aria-describedby="passwordHelp" name="passwordConfirm" value={passwordConfirm} onChange={this.onPWChange}/>
                  {passwordHelp}
  						  </div>
  						  <button disabled={!passwordsMatch} type="submit" class="btn btn-primary">Submit</button>
  						</form>
  				  </div>
  				</div>
        </div>
			</HashRouter>
    );
  }
}
 
export default Register;