import React, { Component } from 'react';
import './Card.css';
import { Redirect } from "react-router-dom";
import AuthService from './components/AuthService';
import sun from "./img/icons8-happy-48.png"
import storm from "./img/icons8-angry-48.png"
import { HalfCircleMeter } from 'react-svg-meters'
import Moment from 'react-moment';
import ApiService from './components/ApiService';
import {CardBody} from './CardBody';

export class Card extends Component {

	constructor(props) {
		super(props);

		this.state = {
      voted: false,
      needToLogin: false,
      voteFailed: false,
      term: props.term,
      isHelp: props.isHelp || false
    };

		this.Auth = new AuthService('localhost');
    this.Api = new ApiService();
	}

  upVote = () => {
    this.postVote(1);
  }

  downVote = () => {
    this.postVote(0);
  }

  postVote(vote) {
    if(this.state.isHelp) { return; }
  	if(!this.Auth.loggedIn()) {
  		this.setState({
        voted: false,
        needToLogin: true
      });
      return;
    }

    var payload = {
			term: { 
				id: this.state.term['id']
			},
			is_pc: vote
		};

    this.Api.addVote(payload)
    .then((response)=> {
    	// Set the state to notify user of 
    	// successful vote
      this.setState({
        voted: true,
        needToLogin: false,
        voteFailed: false,
        term: response.data
      })
    })
    .catch(error => {
    	this.setState({
        voted: false,
        needToLogin: false,
        voteFailed: true
      });
    });
  }

	render() {
		const needToLogin = this.state.needToLogin;
		if(needToLogin === true) {
			return <Redirect to="/login" />;
		}

		const voteFailed = this.state.voteFailed;
		if (voteFailed === true) {
			this.setState({
        voted: false,
        voteFailed: false
      });
      window.alert(`Cannot vote on the same phrase twice!`);
    }

		const voted = this.state.voted;
		if (voted === true) {
			this.setState({
        voted: false,
        voteFailed: false
      });
      window.alert(`Thank you for voting!`);
    }

	  const styles = {
	    meter: {
	      margin: 8
	    },
	    color: {
	    	foreground: '#008000',
	    	background: '#FF0000'
	    },
	    textStyle: {
	      fontFamily: 'sans-serif',
	      fontSize: 20
	    }
	  }

    let upVotes = this.state.term['votes'].filter(function(vote){ return vote['is_pc'] === true; }).length;
    let downVotes = this.state.term['votes'].filter(function(vote){ return vote['is_pc'] === false; }).length;
    let total = upVotes + downVotes;
    let meterValue = total === 0 ? 100 : ((upVotes / total) * 100);
    meterValue = Math.floor(meterValue);
    let textColor = meterValue >= 50 ? styles.color.foreground : styles.color.background;

    const dateToFormat = new Date(this.state.term['created_at']);

    let username = this.state.term['user'];
    if (typeof username === 'object' && username !== null) {
      username = this.state.term['user'].username;
    }
    let voteSpan = total === 1 ? `${total} vote` : `${total} votes`;
		return (
      <div id="ex3" className="card">
        <span className="p1 fa-stack fa-5x has-badge" data-count={voteSpan}>
  			  <div className="card-body">
            <CardBody term={this.state.term}/>
  			    <button className="card-link" onClick={this.upVote}><img className="img-shadow" src={sun} alt="PC" title="PC?" /></button>
  			    <HalfCircleMeter 
  			    	value={meterValue}
  			    	size={100}
  			    	foregroundColor={styles.color.foreground}
          		backgroundColor={styles.color.background}
          		textColor={textColor} 
          		textStyle={styles.textStyle} />
  			    <button className="card-link" onClick={this.downVote}><img className="img-shadow" src={storm} alt="NOT_PC" title="NOT PC?"/></button>
            <br/>
            <small>{meterValue}% of people think this is politically correct</small>
            <br/>
            <small className="added-by">added by: {username} on <Moment format="MMM DD YYYY">{dateToFormat}</Moment></small>
  			  </div>
        </span>
			</div>
		)
	}
}