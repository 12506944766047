import React, { Component } from 'react';
import {
  HashRouter,
  Redirect
} from "react-router-dom";
import AuthService from './components/AuthService';
import ApiService from './components/ApiService';
import AnalyticsService from './components/AnalyticsService';
import './Add.css';

// This link is useful for submitting form data
// https://blog.stvmlbrn.com/2017/04/07/submitting-form-data-with-react.html
export class Add extends Component {
	constructor() {
    super();
    this.state = {
      tweetLink: '',
      title: '',
      subtitle: '',
      text: '',
      is_pc: 0,
      redirectHome: false,
      renderTweetForm: false,
      validTweet: false
    };

    this.Auth = new AuthService('localhost');
    this.Api = new ApiService();
    this.AnalyticsService = new AnalyticsService();
  }

  renderTweetForm = () => {
    this.setState({ renderTweetForm: !this.state.renderTweetForm });
  }

  onChange = (e) => {
    /*
      Because we named the inputs to match their
      corresponding values in state, it's
      super easy to update the state
    */
    this.setState({ [e.target.name]: e.target.value });
  };

  onTweetChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, function () {
      let pattern = /https:\/\/twitter.com\/.+\/status\/(\d+)$/
      if(pattern.test(this.state.tweetLink)) {
        this.setState({ validTweet: true });
      } else {
        this.setState({ validTweet: false });
      }
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    var payload = {};
    if(this.state.renderTweetForm) {
      let pattern = /https:\/\/twitter.com\/.+\/status\/(\d+)$/
      let tweet_id = this.state.tweetLink.match(pattern)[1];
      payload = {
        title: tweet_id,
        is_pc: parseInt(this.state.is_pc),
        text: 'tweet',
        subtitle: 'tweet',
        is_tweet: true,
        tweet: {
          tweet_id: tweet_id
        }
      }
    } else {
      payload = {
      	title: this.state.title,
      	subtitle: this.state.subtitle,
      	text: this.state.text,
      	is_pc: parseInt(this.state.is_pc)
      };
    }

    this.Api.addTerm(payload)
    .then((response)=> {
      this.setState({
        title: payload['title'],
        redirectHome: true
      });
    });
  }

	render() {
    this.AnalyticsService.pageView('/add');
    const redirectHome = this.state.redirectHome;
    if (redirectHome === true) {
      if(this.state.renderTweetForm) {
        window.confirm(`Successfully added tweet ${this.state.tweetLink}`);
      } else {
        window.confirm(`Your term: ${this.state.title} was added successfully!`);        
      }
      return <Redirect to="/" />;
    }

    if(!this.Auth.loggedIn()) {
      return <Redirect to="/login" />;
    }

    const { tweetLink, title, subtitle, text, is_pc, renderTweetForm, validTweet } = this.state;
    let buttonText = renderTweetForm === false ? 'add tweet' : 'add word';
    let tweetHelp;
    if (renderTweetForm && !validTweet) {
      tweetHelp = <small id="tweetHelp" className="form-text text-muted">Must be a valid tweet link</small>
    }
		return (
			<HashRouter>
        <div className="add-wrapper">
				<div className="card">
				  <div className="card-body">
				    <h5 className="card-title">Add a word, phrase or tweet</h5>
            <button type="button" className="btn card-button btn-info" onClick={this.renderTweetForm}>{buttonText}</button>
				    <form onSubmit={this.onSubmit}>
               <div className={`form-group ${!renderTweetForm ? 'hide' : ''}`}>
                <input className="form-control" id="tweetInput" placeholder="https://twitter.com/user/status/12345" required={renderTweetForm} name="tweetLink" value={tweetLink} onChange={this.onTweetChange}/>
              </div>
						  <div className={`form-group ${renderTweetForm ? 'hide' : ''}`}>
						    <input className="form-control" id="exampleFormControlInput1" placeholder="Term/Phrase" required={!renderTweetForm} name="title" value={title} onChange={this.onChange}/>
						  </div>
						  <div className={`form-group ${renderTweetForm ? 'hide' : ''}`}>
						    <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" required={!renderTweetForm} placeholder="Meaning" name="subtitle" value={subtitle} onChange={this.onChange}></textarea>
						  </div>
						  <div className={`form-group ${renderTweetForm ? 'hide' : ''}`}>
						    <textarea className="form-control" id="exampleFormControlTextarea1" rows="2" required={!renderTweetForm} placeholder="Example" name="text" value={text} onChange={this.onChange}></textarea>
						  </div>
               <div className="d-inline-flex">
                  <div className="mr-2">
  						      <label htmlFor="is_pc" className="">IS PC?</label>
                  </div>
                  <div>
    						    <select className="form-control custom-select" id="is_pc" name="is_pc" value={is_pc} onChange={this.onChange}>
    						      <option value='1'>YES</option>
    						      <option value='0'>NO</option>
    						    </select>
                  </div>
  						  </div>
              <div>
                <br/>
                {tweetHelp}
                <br/>
						    <button disabled={(renderTweetForm && !validTweet)} type="submit" className="btn btn-primary card-button">Submit</button>
              </div>
						</form>
				  </div>
				</div>
        </div>
			</HashRouter>
		)
	}
}