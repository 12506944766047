import React, { Component } from 'react';
import { HashRouter } from "react-router-dom";
import {Card} from './Card.js';
import AnalyticsService from './components/AnalyticsService';

export class HelperPopup extends Component {
	constructor() {
    super();
    this.AnalyticsService = new AnalyticsService();
  }

	render() {
    this.AnalyticsService.pageView('/help');

    let item = {
      title: 'Welcome to ISITPC!',
      subtitle: 'An online community for rating political correctness',
      text: 'Use the icons to voice your opinion of political correctness. The meter shows the current state of political correctness on the word, phrase or tweet. Login to add your own content and see how the community responds!',
      votes: []
    };
    
    return (
      <HashRouter>
         <div>
           <Card term={item} key='1' isHelp={true} />
        </div>
      </HashRouter>
    )
  }
}