import React, { Component } from 'react';
import Moment from 'react-moment';

export class CardBody extends Component {
  render() {
    let term = this.props.term;
    if (term.is_tweet) {
      let tweet = term.tweet;
      return (
        <div className="card tweet-card">
           <div className="card-left">
             <div className="verticalListItem">
                <div className="verticalItemImage">
                  <img alt="" src={tweet['profile_image_url']} />
                </div>
                <div class="verticalItemText">
                  <h6 className="no-margin">{tweet['user_name']}</h6>
                  <small className="text-muted">{`@${tweet['user_screen_name']}`}</small>
                </div>
             </div>
           </div>
           <p className="card-text">{tweet['text']}</p>
           <small className="text-muted"><Moment format="h:mm A - DD MMM YYYY">{tweet['created_at']}</Moment></small>
        </div>
      )
    } else {
      return (
        <div>
          <div className="card-left">
            <h5 className="card-title">{term['title']}</h5>
            <h6 className="card-subtitle mb-2 text-muted">{term['subtitle']}</h6>
          </div>
          <p className="card-text">{term['text']}</p>
        </div>
      )
    }
	}
};
