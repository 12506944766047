import decode from 'jwt-decode';
import axios from "axios";
import AnalyticsService from './AnalyticsService';

export default class AuthService {
    constructor(domain) {
        this.domain = process.env.REACT_APP_API_URL;
        this.AnalyticsService = new AnalyticsService();

        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(username, password) {
        this.AnalyticsService.event('User', 'login');

        var payload = {
            username: username,
            password: password
        };

        return axios.get(`${this.domain}/generate-valid-token`, {
             headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             },
             auth: payload
        }).then(res => {
            this.AnalyticsService.event('User', 'Login Successful');
            this.setToken(res.data.token)
            return Promise.resolve(res);
        });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        this.AnalyticsService.event('User', 'logout');
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }
}