import React from 'react';
import { compose } from 'recompose';
import {Card} from './Card.js'

const withLoading = (Component) => (props) =>
  <div>
    <Component {...props} />

    <div className="interactions">
      {props.isLoading && <span>Loading...</span>}
    </div>
  </div>

const List = ({ list, isLoading, onPaginatedSearch, totalReached }) =>
  <div>
    <div className="wrapper">
      <div className="card-list">
        { list.map( (item, i) => <Card term={item} key={i} /> ) }
        { !isLoading && !totalReached && <button className="btn" type="button" onClick={onPaginatedSearch}>I want more!</button> }
      </div>
    </div>
  </div>

export const ListWithLoading = compose(
  withLoading
)(List);


export const applyUpdateResult = (result) => (prevState) => ({
  hits: [...prevState.hits, ...result.data.terms],
  page: result.data.page,
  isLoading: false,
  totalReached: [...prevState.hits, ...result.data.terms].length === result.data.totalItems
});

export const applySetResult = (result) => (prevState) => ({
  hits: result.data.terms,
  page: result.data.page,
  isLoading: false,
  totalReached: result.data.terms.length === result.data.totalItems
});