import React, { Component } from 'react';
import { Route, HashRouter } from "react-router-dom";
import './App.css';
import {NavBar} from './NavBar.js'
import Register from './Register.js'
import {Login} from './Login.js'
import {Add} from './Add.js'
import {SearchResults} from './SearchResults.js'
import {HelperPopup} from './HelperPopup.js';
import {Home} from './Home.js'

class App extends Component {
  // This was a useful link for passing props to components when using HashRouter
  // https://tylermcginnis.com/react-router-pass-props-to-components/
  render() {
    return (
    	<HashRouter>
				<div className="App" >
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>
					<NavBar />
          <Route exact path="/" component={Home}/>
	        <Route path="/register" component={Register}/>
          <Route path="/login" component={Login}/>
          <Route path="/add" component={Add}/>
          <Route path="/help" component={HelperPopup} />
          <Route path="/results/:searchTerm" component={SearchResults}/>
	      </div>
      </HashRouter>
    );
  }
}

export default App;
