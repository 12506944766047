import React, { Component } from 'react';
import './NavBar.css';
import { NavLink } from "react-router-dom";
import logo from "./img/logo.png"

export class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      searchTerm: '',
      showPopup: false
    };
  }

  onChange = (e) => {
    /*
      Because we named the inputs to match their
      corresponding values in state, it's
      super easy to update the state
    */
    this.setState({ [e.target.name]: e.target.value });
  }

  showPopup = () => {
    if(this.state.showPopup ) {
      this.setState({showPopup: false});
    } else {
      this.setState({showPopup: true});
    }
  }

  render() {
    const { searchTerm, showPopup } = this.state;

    // Show helperCard at /popup link
    let link = "/#/"
    if(showPopup && window.location.href.endsWith("/#/")) {
      link = "/#/help"
    }

    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <div className="flex-column ml-lg-0 ml-3" id="navbarCollapse">
          <ul className="navbar-nav mx-auto">
            <li className="active text-center">
              <a className="" href={link} onClick={this.showPopup}>
                <img className="logo-image" src={logo} alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div className="flex-column ml-lg-0 ml-3 nav-column-2" id="navbarCollapse">
          <ul className="navbar-nav mx-auto nav-items">
            <li className="nav-item">
              <a className="nav-link" href="/#/add">
                <i className="material-icons white-icon">add_box</i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#/login">
                <i className="material-icons white-icon">account_box</i>
              </a>
            </li>
          </ul>
          <ul className="navbar-nav flex-row mb-2 search-bar">
            <form className="form-inline my-2 my-lg-0 w-100 d-inline" action="/" method="GET">
              <input className="form-control mr-sm-2 search-input" type="search" placeholder="Search for words or phrases" aria-label="Search" name="searchTerm" value={searchTerm} onChange={this.onChange}></input>
              <NavLink to={`/results/${this.state.searchTerm}`} replace>
                <button className="btn my-2 my-sm-0 navbutton search-button" type="submit">Search</button>
              </NavLink>
            </form>
          </ul>
        </div>
      </nav>
    )
  }
};
