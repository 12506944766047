import React, { Component } from 'react';
import {HashRouter} from "react-router-dom";
import './Home.css';
import {ListWithLoading, applyUpdateResult, applySetResult} from './ListWithLoading.js'
import ApiService from './components/ApiService';
import AnalyticsService from './components/AnalyticsService';

export class Home extends Component {
	constructor() {
    super();
    this.state = {
      terms: [],
      hits: [],
      page: null,
      isLoading: false,
      totalReached: false
    };

    this.Api = new ApiService();
    this.AnalyticsService = new AnalyticsService();
  }

	componentDidMount() {
		this.fetchTerms(0);
  }

  onPaginatedSearch = (e) =>
    this.fetchTerms(this.state.page + 1);

  fetchTerms(page) {
    this.setState({ isLoading: true });
		this.Api.getTerms(page)
      .then(result => this.onSetResult(result, page))
      .catch(error => console.log(error));
  }

  onSetResult(result, page) {
    if ( page === 0 ) {
      this.setState(applySetResult(result));
    } else {
      this.setState(applyUpdateResult(result));
    }
  }

	render() {
    this.AnalyticsService.pageView('/');

		return (
			<HashRouter>
         <div>
           <ListWithLoading
            list={this.state.hits}
            page={this.state.page}
            isLoading={this.state.isLoading}
            onPaginatedSearch={this.onPaginatedSearch}
            totalReached={this.state.totalReached}
          />
        </div>
			</HashRouter>
		)
	}
}