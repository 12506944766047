import React, { Component } from 'react';
import { HashRouter } from "react-router-dom";
import './SearchResults.css';
import {ListWithLoading, applyUpdateResult, applySetResult} from './ListWithLoading.js'
import ApiService from './components/ApiService';
import AnalyticsService from './components/AnalyticsService';

export class SearchResults extends Component {
	constructor() {
    super();
    this.state = {
      terms: [],
      hits: [],
      page: null,
      isLoading: false,
      totalReached: false
    };

    this.Api = new ApiService();
    this.AnalyticsService = new AnalyticsService();
  }

	componentDidMount() {
		this.fetchSearchTerms(this.props.match.params.searchTerm, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.searchTerm !== nextProps.match.params.searchTerm) {
      this.setState({ hits: [] });
      this.fetchSearchTerms(nextProps.match.params.searchTerm, 0);
    }
  }

  onPaginatedSearch = (e) =>
    this.fetchSearchTerms(this.props.match.params.searchTerm, this.state.page + 1);

  fetchSearchTerms(term, page) {
      this.Api.searchTerms(term, page)
      .then(result => this.onSetResult(result, page))
      .catch(error => console.log(error));
  }

  onSetResult(result, page) {
    if ( page === 0 ) {
      this.setState(applySetResult(result));
    } else {
      this.setState(applyUpdateResult(result));
    }
  }

	render() {
    this.AnalyticsService.pageView('/results');
    
    return (
      <HashRouter>
         <div>
           <ListWithLoading
            list={this.state.hits}
            page={this.state.page}
            isLoading={this.state.isLoading}
            onPaginatedSearch={this.onPaginatedSearch}
            totalReached={this.state.totalReached}
          />
        </div>
      </HashRouter>
    )
  }
}