import axios from "axios";
import AuthService from './AuthService';
import AnalyticsService from './AnalyticsService';

export default class ApiService {
    constructor() {
        this.domain = process.env.REACT_APP_API_URL;
        this.Auth = new AuthService('localhost');
        this.AnalyticsService = new AnalyticsService();

        // initialize functions
        this.getTerms = this.getTerms.bind(this);
        this.searchTerms = this.searchTerms.bind(this);
        this.addTerm = this.addTerm.bind(this);
        this.addVote = this.addVote.bind(this);
        this.createUser = this.createUser.bind(this);
    }

    getTerms(page) {
        this.AnalyticsService.event('Api', 'getTerms');
        const url = `${this.domain}/terms?page=${page}`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => { return Promise.resolve(res) });
    }

    searchTerms(term, page) {
        this.AnalyticsService.event('Api', 'searchTerms');
        const url = `${this.domain}/terms/search?title=${term}&page=${page}`
        return axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => { return Promise.resolve(res) });
    }

    addTerm(payload) {
        this.AnalyticsService.event('Api', 'addTerm');
        return axios.post(`${this.domain}/terms/withInitialVote`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "bearer " + this.Auth.getToken()
            },
        }).then(res => { return Promise.resolve(res) });
    }

    addVote(payload) {
        this.AnalyticsService.event('Api', 'addVote');
        return axios.post(`${this.domain}/votes`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': "bearer " + this.Auth.getToken()
            },
        }).then(res => { return Promise.resolve(res) });
    }

    createUser(payload) {
        this.AnalyticsService.event('User', 'createUser');
        return axios.post(`${this.domain}/users`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => { return Promise.resolve(res) });
    }
}