import React, { Component } from 'react';
import {
  NavLink,
  HashRouter,
  Redirect
} from "react-router-dom";
import AuthService from './components/AuthService';
import AnalyticsService from './components/AnalyticsService';

export class Login extends Component {
	constructor() {
    super();
    this.state = {
    	username: '',
      password: '',
      redirectHome: false
    };

    this.Auth = new AuthService('localhost');
    this.AnalyticsService = new AnalyticsService();
  }

  onChange = (e) => {
    /*
      Because we named the inputs to match their
      corresponding values in state, it's
      super easy to update the state
    */
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.Auth.login(this.state.username,this.state.password)
      .then(res => {
        this.setState({
          username: this.state.username,
          redirectHome: true
        });
      }).catch(err =>{
        alert('Username/Password not found!  Please try again.');
      })
  }

	render() {
    this.AnalyticsService.pageView('/login');
		const redirectHome = this.state.redirectHome;
    if (redirectHome === true) {
      window.confirm(`Logged in successfully as ${this.state.username}!`);
      return <Redirect to="/" />;
    }

    if(this.Auth.loggedIn()) {
      let logout = window.confirm(`Logout?`);
      if (logout) {
        this.Auth.logout();
      }
      return <Redirect to="/" />;
    }

    const { username, password } = this.state;
		return (
			<HashRouter>
				<div class="card">
				  <div class="card-body">
				    <h5 class="card-title">Login</h5>
				    <form onSubmit={this.onSubmit}>
						  <div class="form-group">
						    <input class="form-control" id="exampleInputEmail1" placeholder="Username" name="username" value={username} onChange={this.onChange}/>
						  </div>
						  <div class="form-group">
						    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" name="password" value={password} onChange={this.onChange}/>
						  </div>
						  <button type="submit" class="btn btn-primary">Submit</button>
						</form>

						<NavLink to="/register" onClick={this.props.closePopup}><small class="form-text text-muted">Register</small></NavLink>
				  </div>
				</div>
			</HashRouter>
		)
	}
}